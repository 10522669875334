import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import routes from 'Config/routes'
import { ReactComponent as RecepcionConfirmada } from 'Assets/Icons/RecepcionConfirmada.svg'
import { ReactComponent as RecepcionEspera } from 'Assets/Icons/RecepcionEspera.svg'
import { AuthActions } from 'Redux/auth'
import { TicketActions } from 'Redux/ticket'
import { setIsCreateQa } from 'Redux/multicenter/actions'
import { selectConfig } from 'Redux/auth/utils'

import {
  Container,
  Content,
  Title,
  SubTitle,
  SvgContainer,
  ButtonsContainer,
  useStyles,
  InfoContainer,
  NoComplianceInfoLiteral,
} from './styled'

const DeliveryConfirmed = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { providerId, orderId, documentTypeCode, documentCompany } = useParams()
  const dispatch = useDispatch()

  const isMulticenterCreateQa = useSelector(({ multicenter }) => multicenter.isCreateQa)
  const features = useSelector(selectConfig)

  const handleClickHistoric = () => history.push(routes.historic)
  const handleClickOpenNoConformities = () => {
    dispatch(setIsCreateQa(false))
    dispatch(TicketActions.setIsNotServedOrder(false))
    history.push(routes.addTicketing(providerId, orderId, documentTypeCode, documentCompany))
  }

  const handleClickBackMulticenter = () => {
    dispatch(AuthActions.setCenter({}))
    dispatch(setIsCreateQa(false))
    history.push(routes.multicenterOrders)
  }

  const Icon = features?.order_reception_sqs ? RecepcionConfirmada : RecepcionEspera

  return (
    <Container>
      <Content>
        <Title>{t(features?.order_reception_sqs ? 'orderRecievedTitleSQS' : 'orderRecievedTitle')}</Title>
        <SubTitle>
          {t(
            features?.order_reception_sqs
              ? 'orderRecievedSubtitleSQS'
              : isMulticenterCreateQa
              ? 'orderRecievedSubtitleMulticenter'
              : 'orderRecievedSubtitle'
          )}
        </SubTitle>
        <SvgContainer>
          <Icon style={{ width: '160px', height: '160px' }} />
        </SvgContainer>
        <ButtonsContainer>
          <Button onClick={handleClickOpenNoConformities} className={classes.compliance}>
            {t('openNoCompliance')}
          </Button>
          {isMulticenterCreateQa ? (
            <Button onClick={handleClickBackMulticenter} className={classes.history}>
              {t('backToMyOrders')}
            </Button>
          ) : (
            <Button onClick={handleClickHistoric} className={classes.history}>
              {t('backToHistory')}
            </Button>
          )}
        </ButtonsContainer>
        <InfoContainer>
          <NoComplianceInfoLiteral>{t('fillOutDTA')}</NoComplianceInfoLiteral>
        </InfoContainer>
      </Content>
    </Container>
  )
}

export default DeliveryConfirmed
