import { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import routes from 'Config/routes'
import { AuthActions } from 'Redux/auth'
import { TicketActions } from 'Redux/ticket'
import { PurchaseActions } from 'Redux/purchases'
import {
  getDeliveryQa,
  getDeliveryQaExcel,
  getDownloadHistoric,
  getOrderProducts,
  getProviderOrdersExcel,
} from 'Services/api'
import * as Sentry from 'Services/sentry'

const useOrderActions = (center, setShowOrderProducts, setShowRepeatOrder, setShowDownloadModal, setShowQAModal) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const [orderProducts, setOrderProducts] = useState({})
  const [selectedOrder, setSelectedOrder] = useState()
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)
  const [repeatOrderConfirmed, setRepeatOrderConfirmed] = useState(false)
  const [qaData, setQAData] = useState()

  const isLoadingRepeatOrder = useSelector(({ purchase }) => purchase.isLoadingRepeatOrder)
  const filters = useSelector(({ multicenter }) => multicenter.ordersFilters)

  useEffect(() => {
    if (!isLoadingRepeatOrder && repeatOrderConfirmed) {
      dispatch(AuthActions.setCenter({ ...center }))
      setRepeatOrderConfirmed(false)
      history.push(routes.multicenterRepeatOrder)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRepeatOrder, repeatOrderConfirmed])

  const handleViewProducts = useCallback(
    order => {
      const { id, documentCompany, documentTypeCode } = order

      setOrderProducts({})
      setIsLoadingProducts(true)
      setShowOrderProducts(true)

      getOrderProducts({}, center.id, id, documentTypeCode, documentCompany)
        .then(setOrderProducts)
        .finally(() => setIsLoadingProducts(false))
    },
    [center.id, setShowOrderProducts]
  )

  const handleRepeatOrder = useCallback(
    order => {
      setShowRepeatOrder(true)
      setSelectedOrder(order)
    },
    [setShowRepeatOrder]
  )

  const handleRepeatOrderConfirm = useCallback(() => {
    const { id, documentTypeCode, documentCompany } = selectedOrder
    const repeatOrderParams = {
      documentTypeCode,
      documentCompany,
      centerId: center.id,
      orderId: id,
    }
    setRepeatOrderConfirmed(true)
    dispatch(PurchaseActions.fetchRepeatOrder(repeatOrderParams))
  }, [selectedOrder, center.id, dispatch])

  const handleDownload = useCallback(
    order => {
      setSelectedOrder(order)
      setShowDownloadModal(true)
    },
    [setShowDownloadModal]
  )

  const handleViewQA = useCallback(
    order => {
      getDeliveryQa(order.id, order.documentTypeCode, order.documentCompany, center.id)
        .then(response => {
          setQAData(response)
          setShowQAModal(true)
        })
        .catch(e => {
          Sentry.captureException(e)
          toast.error(t('petitionError'))
        })
    },
    [center, setShowQAModal, t]
  )

  const handleViewNoCompliance = useCallback(
    order => {
      dispatch(AuthActions.setCenter({ ...center }))
      dispatch(TicketActions.setIsNotServedOrder(false))
      history.push(routes.addTicketing(order.provider.id, order.id, order.documentTypeCode, order.documentCompany))
    },
    [center, history, dispatch]
  )

  const handleDownloadAll = useCallback(async () => {
    if (!Object.keys(filters).length) {
      return
    }

    await getDownloadHistoric(filters, center.id)
  }, [center, filters])

  const handleNewOrder = useCallback(() => {
    dispatch(AuthActions.setCenter({ ...center }))
    history.push(routes.categories)
  }, [center, history, dispatch])

  const downloadSingleOrder = useCallback(async () => {
    if (!selectedOrder) {
      return
    }

    await getProviderOrdersExcel(
      {},
      center.id,
      selectedOrder.id,
      selectedOrder.documentTypeCode,
      selectedOrder.documentCompany
    )
  }, [center.id, selectedOrder])

  const downloadQA = useCallback(async () => {
    if (!selectedOrder) {
      return
    }

    await getDeliveryQaExcel(selectedOrder.id, selectedOrder.documentTypeCode, selectedOrder.documentCompany, center.id)
  }, [center.id, selectedOrder])

  return {
    isLoadingProducts,
    isLoadingRepeatOrder,
    orderProducts,
    qaData,
    handleViewProducts,
    handleRepeatOrder,
    handleRepeatOrderConfirm,
    handleDownload,
    handleDownloadAll,
    handleNewOrder,
    handleViewQA,
    handleViewNoCompliance,
    downloadSingleOrder,
    downloadQA,
  }
}

export default useOrderActions
